import "focus-visible";
import "../styles/main.scss";
import { registerModileNavigation } from "./nav-mobile";
import { registerAwakeness } from "./awakeness";
import { registerYM } from "./ym";
import "./table-infogr";
import "./scroll-parallax";
import "./scroll-links";
// import "./flex-table";
import "./utm-saver";
import { registerLazyPlayer, registerInstantPlayers } from "./plyr";
import "./tmp-chrome-plugin";
import "./publications";
// import "./copy-text-button";
import "./screenshot-gallery";
import "./menu-dropdown";
import "./likely";
import "./academy-popup";
// import "./pricing";
import "./referrer-saver";
import "./slideshow-animation";
import "./expand-clients";
import "./slider-slick";

/**
 * @param {Event} event
 */
function stopPropagation(event) {
  event.stopPropagation();
}

function ready() {
  const pseudoBrowserLinks = document.querySelectorAll(".pseudo-browser a");

  for (const link of pseudoBrowserLinks) {
    link.addEventListener("click", stopPropagation);
  }

  registerModileNavigation();
  registerAwakeness();
  registerYM();
  document.addEventListener("scroll", function() {
    registerLazyPlayer("#how-to-work", ".lazy-player");
    registerInstantPlayers(".plyr-player");
  });
}

window.addEventListener("DOMContentLoaded", ready);
