import $ from "jquery";

const ready = function() {
  $(".js-scroll-link").on("click", function(e) {
    e.preventDefault();
    const headerHeight = document.querySelector("header").clientHeight;

    var linkOffset = -1 * headerHeight * 3.95;

    if ($.attr(this, "href") === "#scroll-to-top") {
      $("html, body").animate({ scrollTop: 0 }, "slow");
      return;
    }

    if ($($.attr(this, "href")).data("scroll-link-offset")) {
      linkOffset += $($.attr(this, "href")).data("scroll-link-offset");
    }

    $(".mobile-nav-sidebar").removeClass("visible");

    $("html, body").animate(
      {
        scrollTop: $($.attr(this, "href")).offset().top + linkOffset
      },
      500
    );
  });
};

$(document).ready(ready);
